@import '~bootstrap/scss/bootstrap.scss';

body {
  align-items: center;
  align-content: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-align: 'justify' !important;
}

.form-header {
  background-color: #2c3d7b;
  margin: 1rem;
  padding: 1rem;
  color: white;
  font-weight: bolder;
}

.select {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}

.white {
  color: white;
}

button {
  background-color: #2c3e7c !important;
  margin-top: 25px;
  margin-bottom: 25px;
}

button:hover {
  background-color: #b58e43 !important;
  color: white !important;
  border: 2px solid #b58e43 !important;
}

.begin-button {
  width: 100%;
  max-height: 5rem;
  height: 5rem;
  font-size: larger;
  font-weight: 500;
  background-color: white !important;
  color: #2c3e7c !important;
}

.next-button {
  width: 7rem;
  max-height: 3rem;
  height: 3rem;
}

.submit-button {
  background-color: #11611e !important;
}
.submit-button:hover {
  background-color: #367240 !important;
}

.big-button {
  width: 100%;
  height: auto;
}

.medium-button {
  width: 70%;
  height: auto;
}

.small-button {
  width: 50%;
  height: auto;
}

.make-logo {
  max-width: 10rem;
  max-height: 10rem;
  border: 2px solid #2c3e7c;
  margin: 1rem;
}

.make-logo:hover {
  border: 3px solid gray;
}

.zip-code-title {
  font-weight: bolder;
}

.zip-code-field {
  border-radius: 10px;
  border: 3px solid #b58e43 !important;
}

.zip-code-div {
  padding-top: 5%;
  background-color: #2c3d7b;
  color: white;
  border-radius: 5px;
}

.center-page {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
}
